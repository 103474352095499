import { ApiClient } from 'jsonapi-react'
import { useUser } from './store/user'

export const client = new ApiClient({
	url: import.meta.env.VITE_API_BASE_URL,
	fetch: async (url: string, options: any) => {
		const user = useUser.getState().user

		options.headers = {
			...options.headers,
			...(user?.id ? { 'X-UserId': user.id } : {}),
		}

		let [newUrl = '/', params = null] = url?.split('?')

		// if (!newUrl.endsWith('/')) {
		//   newUrl += '/'
		// }

		return await fetch(newUrl + (params ? '?' + params : ''), options)
	},
	schema: {
		hidden_words: {
			type: 'HiddenWordsUserState',
		},
		sudoku: {
			type: 'SudokuUserState',
		},
	},
})
